import Accordion, { AccordionItem } from 'src/components/ui/Accordion'

export const ShowAccordion = (
  indicesExpanded: Set<number>,
  onChange: (index: number) => void,
  description: string,
  isIndexExpanded: boolean
  // eslint-disable-next-line max-params
) => {
  return (
    <Accordion
      expandedIndices={indicesExpanded}
      onChange={onChange}
      className="product-details__description-accordion"
    >
      <AccordionItem
        iconNameContracted="ArrowDown"
        iconNameExpanded="ArrowUp"
        accordionItemClass="product-details__accordion-item"
        isExpanded={isIndexExpanded}
        buttonLabel="Descripción"
      >
        <p className="text__body-accordion">{description}</p>
      </AccordionItem>
    </Accordion>
  )
}
