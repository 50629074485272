/* eslint-disable react-hooks/rules-of-hooks */
import { RadioOption as UIRadioOption } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'

import styles from './sku-selector.module.scss'
import type { SkuSelectorProps } from './UISkuSelector'
import UISkuSelector from './UISkuSelector'

function SkuSelector({ ...props }: SkuSelectorProps) {
  const { options, activeValue, variant } = props
  /* const PRODUCT_OUT_OF_STOCK_LABEL = 'https://schema.org/OutOfStock' */

  return (
    <UISkuSelector className={styles.fsSkuSelector} {...props}>
      {options.map((option, index) => {
        /* const productIsOutOfStock =
          getSkuDetails(option?.sku ?? '').data?.product.offers.offers[0]
            .availability === PRODUCT_OUT_OF_STOCK_LABEL */

        return (
          <UIRadioOption
            data-fs-sku-selector-option
            key={String(index)}
            label={option.label}
            value={option.value}
            disabled={option.disabled}
            checked={option.value === activeValue}
          >
            {variant === 'label' && <span>{option.value}</span>}
            {variant === 'image' && 'src' in option && (
              <span>
                <Image
                  src={option.src ?? ''}
                  alt={option.alt}
                  width={20}
                  height={20}
                  loading="lazy"
                  data-fs-sku-selector-option-image
                />
              </span>
            )}
          </UIRadioOption>
        )
      })}
    </UISkuSelector>
  )
}

export default SkuSelector
