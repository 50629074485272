export default function ShareIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 18C20.25 19.2426 19.2426 20.25 18 20.25V21.75C20.0711 21.75 21.75 20.0711 21.75 18H20.25ZM18 20.25C16.7574 20.25 15.75 19.2426 15.75 18H14.25C14.25 20.0711 15.9289 21.75 18 21.75V20.25ZM15.75 18C15.75 16.7574 16.7574 15.75 18 15.75V14.25C15.9289 14.25 14.25 15.9289 14.25 18H15.75ZM18 15.75C19.2426 15.75 20.25 16.7574 20.25 18H21.75C21.75 15.9289 20.0711 14.25 18 14.25V15.75ZM20.25 6C20.25 7.24264 19.2426 8.25 18 8.25V9.75C20.0711 9.75 21.75 8.07107 21.75 6H20.25ZM18 8.25C16.7574 8.25 15.75 7.24264 15.75 6H14.25C14.25 8.07107 15.9289 9.75 18 9.75V8.25ZM15.75 6C15.75 4.75736 16.7574 3.75 18 3.75V2.25C15.9289 2.25 14.25 3.92893 14.25 6H15.75ZM18 3.75C19.2426 3.75 20.25 4.75736 20.25 6H21.75C21.75 3.92893 20.0711 2.25 18 2.25V3.75ZM8.25 12C8.25 13.2426 7.24264 14.25 6 14.25V15.75C8.07107 15.75 9.75 14.0711 9.75 12H8.25ZM6 14.25C4.75736 14.25 3.75 13.2426 3.75 12H2.25C2.25 14.0711 3.92893 15.75 6 15.75V14.25ZM3.75 12C3.75 10.7574 4.75736 9.75 6 9.75V8.25C3.92893 8.25 2.25 9.92893 2.25 12H3.75ZM6 9.75C7.24264 9.75 8.25 10.7574 8.25 12H9.75C9.75 9.92893 8.07107 8.25 6 8.25V9.75ZM15.651 15.987L9.02921 12.6761L8.35839 14.0177L14.9802 17.3286L15.651 15.987ZM9.0198 11.3286L15.6479 8.01459L14.977 6.67295L8.34898 9.98698L9.0198 11.3286Z"
        fill="#E5002B"
      />
    </svg>
  )
}
