import IconMail from '../../Icons/SocialMedia/IconMail'
import TwitterIcon from '../../Icons/SocialMedia/TwitterIcon'
import FacebookIcon from '../../Icons/SocialMedia/FacebookIcon'
import WhatsAppIcon from '../../Icons/SocialMedia/WhatsAppIconShare'

function useShareProductsUrl(locationHref: string) {
  if (locationHref !== '') {
    return [
      {
        target: '_blank',
        name: 'Facebook',
        url: `https://www.facebook.com/sharer.php?u=${locationHref}`,
        SocialIcon: FacebookIcon,
      },
      {
        target: '_blank',
        name: 'Twitter',
        url: `https://twitter.com/share?text=Confira%20este%20produto:&hashtags=Fashion,FashioStarter&url=${locationHref}`,
        SocialIcon: TwitterIcon,
      },
      {
        target: '_blank',
        name: 'WhatsApp',
        url: `https://api.whatsapp.com/send?text=Confira%20este%20produto:${locationHref}`,
        SocialIcon: WhatsAppIcon,
      },
      {
        target: '_blank',
        name: 'E-mail',
        url: `mailto:?subject=Confira%este%produto&amp;body=Confira este produto ${locationHref}`,
        SocialIcon: IconMail,
      },
    ]
  }
  return null
}

export default useShareProductsUrl
