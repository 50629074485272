import Icon from 'src/components/ui/Icon'

export const MessageStock = () => (
  <div className="pdp__stock">
    <Icon name="Stock" width="24px" height="24px" />
    <p>Producto sujeto a disponibilidad de stock.</p>
  </div>
)

export const MessageIndisponible = () => (
  <div className="sku__indisponible">
    <Icon name="Unavailable" width="24px" height="24px" />
    <p className="sku__message">Producto no disponible.</p>
  </div>
)
