export default function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17.9"
      viewBox="0 0 18 17.9"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 13.45 3.25 17.15 7.5 17.9L7.55017 17.8599C7.53344 17.8566 7.51672 17.8533 7.5 17.85V11.5H5.25V9H7.5V7C7.5 4.75 8.95 3.5 11 3.5C11.65 3.5 12.35 3.6 13 3.7V6H11.85C10.75 6 10.5 6.55 10.5 7.25V9H12.9L12.5 11.5H10.5V17.85C10.4833 17.8533 10.4666 17.8566 10.4498 17.8599L10.5 17.9C14.75 17.15 18 13.45 18 9C18 4.05 13.95 0 9 0C4.05 0 0 4.05 0 9Z"
        fill="#292929"
      />
    </svg>
  )
}
