export default function TwitterIcon() {
  return (
    <svg
      width="15.49"
      height="13.63"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6611 4.07632C14.6669 4.22622 14.6689 4.37606 14.6689 4.52596C14.6689 9.07071 11.4719 14.3173 5.62564 14.3173C3.82978 14.3173 2.1599 13.745 0.75293 12.7706C1.00164 12.7979 1.25422 12.8184 1.51068 12.8184C2.99969 12.8184 4.37113 12.2663 5.45898 11.3397C4.06816 11.3192 2.89375 10.3176 2.48871 8.95487C2.68316 8.99576 2.8834 9.01626 3.08818 9.01626C3.37694 9.01626 3.6573 8.97542 3.92603 8.89366C2.47061 8.58023 1.37438 7.19026 1.37438 5.52091C1.37438 5.50046 1.37438 5.49361 1.37438 5.47998C1.80331 5.73209 2.29426 5.88873 2.81557 5.90917C1.96157 5.28912 1.4002 4.23302 1.4002 3.04062C1.4002 2.41376 1.55653 1.82094 1.83172 1.30991C3.3989 3.39491 5.74192 4.76448 8.38338 4.90757C8.32912 4.65546 8.30133 4.3898 8.30133 4.12406C8.30133 2.22304 9.72444 0.683105 11.4802 0.683105C12.3943 0.683105 13.2199 1.0988 13.7994 1.76655C14.5248 1.61664 15.2044 1.33049 15.8194 0.935293C15.581 1.73931 15.0778 2.41373 14.4201 2.83618C15.0636 2.75441 15.6773 2.57058 16.2464 2.29803C15.8194 2.98622 15.2819 3.59255 14.6611 4.07632Z"
        fill="#292929"
      />
    </svg>
  )
}
