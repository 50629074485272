export default function WhatsAppIconShare() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.16"
      height="17.23"
      viewBox="0 0 22 22"
    >
      <defs>
        <clipPath id="figfa">
          <path d="M16.654 13.185c-.067-.11-.243-.175-.508-.307-.265-.13-1.567-.767-1.809-.854-.242-.088-.42-.132-.596.131s-.683.855-.838 1.03c-.154.176-.309.198-.574.066-.264-.131-1.117-.409-2.128-1.304-.787-.696-1.319-1.555-1.473-1.818-.154-.263-.016-.405.116-.536.12-.118.265-.307.397-.46.133-.154.177-.263.265-.438.088-.176.044-.329-.022-.46-.066-.132-.596-1.425-.817-1.95-.22-.526-.44-.439-.595-.439-.154 0-.331-.022-.508-.022a.977.977 0 0 0-.706.329c-.242.263-.926.898-.926 2.19 0 1.293.948 2.542 1.08 2.717.133.175 1.832 2.914 4.523 3.966 2.692 1.051 2.692.7 3.177.657.485-.044 1.566-.636 1.788-1.249.22-.614.22-1.14.154-1.25zm3.626-2.468c0 4.968-4.073 9.01-9.08 9.01a9.074 9.074 0 0 1-4.996-1.49l-3.489 1.108L3.85 16a8.915 8.915 0 0 1-1.73-5.282c0-4.968 4.073-9.01 9.08-9.01s9.08 4.042 9.08 9.01zM11.2 0C5.235 0 .4 4.798.4 10.717c0 2.024.566 3.918 1.55 5.533L0 22l5.98-1.9a10.818 10.818 0 0 0 5.22 1.333c5.965 0 10.8-4.798 10.8-10.716C22 4.798 17.166 0 11.2 0z" />
        </clipPath>
      </defs>
      <g>
        <g>
          <g>
            <g>
              <path d="M16.654 13.185c-.067-.11-.243-.175-.508-.307-.265-.13-1.567-.767-1.809-.854-.242-.088-.42-.132-.596.131s-.683.855-.838 1.03c-.154.176-.309.198-.574.066-.264-.131-1.117-.409-2.128-1.304-.787-.696-1.319-1.555-1.473-1.818-.154-.263-.016-.405.116-.536.12-.118.265-.307.397-.46.133-.154.177-.263.265-.438.088-.176.044-.329-.022-.46-.066-.132-.596-1.425-.817-1.95-.22-.526-.44-.439-.595-.439-.154 0-.331-.022-.508-.022a.977.977 0 0 0-.706.329c-.242.263-.926.898-.926 2.19 0 1.293.948 2.542 1.08 2.717.133.175 1.832 2.914 4.523 3.966 2.692 1.051 2.692.7 3.177.657.485-.044 1.566-.636 1.788-1.249.22-.614.22-1.14.154-1.25zm3.626-2.468c0 4.968-4.073 9.01-9.08 9.01a9.074 9.074 0 0 1-4.996-1.49l-3.489 1.108L3.85 16a8.915 8.915 0 0 1-1.73-5.282c0-4.968 4.073-9.01 9.08-9.01s9.08 4.042 9.08 9.01zM11.2 0C5.235 0 .4 4.798.4 10.717c0 2.024.566 3.918 1.55 5.533L0 22l5.98-1.9a10.818 10.818 0 0 0 5.22 1.333c5.965 0 10.8-4.798 10.8-10.716C22 4.798 17.166 0 11.2 0z" />
            </g>
            <g clipPath="url(#figfa)">
              <g>
                <path d="M0 0h22v22H0z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
