import { sendAnalyticsEvent } from '@faststore/sdk'
import type { Item } from '@faststore/sdk'

type AdditionalItemProperties = {
  product_reference_id: string | null
  item_variant_name: string | null
}

export type AnalyticsItem = Item & AdditionalItemProperties

export interface SearchSelectItemParams {
  url: string
  items: Array<{
    item_id?: string
    item_variant?: string
    index: number
  }>
}

export interface SearchSelectItemEvent {
  name: 'search_select_item'
  params: SearchSelectItemParams
}

export interface PageViewParams {
  location: string
  page: string
  referrer?: string
  title: string
  originalLocation: string
  originalReferrer?: string
}

export interface PageViewEvent {
  name: 'page_view'
  params: PageViewParams
}

type ViewItemExtended = {
  name: string
  params: string
}

type SelectItemExtended = {
  name: string
  params: string
}

type ExtendedEvents = PageViewEvent | ViewItemExtended | SelectItemExtended

type SendExtendedAnalyticsEvent = (event: ExtendedEvents) => void

export const sendExtendedAnalyticsEvent: SendExtendedAnalyticsEvent = (event) =>
  sendAnalyticsEvent<ExtendedEvents>(event)
