import { useEffect, useState } from 'react'

export const useOnScreen = () => {
  const [inView, setInView] = useState(false)
  const [windowScrollYPosition, setWindowScrollYPosition] = useState(0)

  const observeScrollY = () => {
    if (window?.scrollY > 0) {
      setInView(true)
    }

    setWindowScrollYPosition(window?.scrollY)
  }

  useEffect(() => {
    if (typeof window === 'undefined' || inView) {
      return
    }

    window.addEventListener('scroll', observeScrollY)
    return () => window.removeEventListener('scroll', observeScrollY)
  }, [windowScrollYPosition])

  return { inView }
}

export default useOnScreen
