import { useCallback } from 'react'

import type { PageViewParams } from '../types'
import { sendExtendedAnalyticsEvent } from '../types'

export const usePageViewEvent = () => {
  const sendpageViewEvent = useCallback(
    ({ location, page, title, originalLocation }: PageViewParams) => {
      sendExtendedAnalyticsEvent({
        name: 'page_view',
        params: {
          location,
          page,
          title,
          originalLocation,
        },
      })
    },
    []
  )

  return { sendpageViewEvent }
}
