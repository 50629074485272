import { useState } from 'react'
import { Image } from 'src/components/ui/Image'
import ShareProduct from '@acctglobal/shareproduct'
import Icon from 'src/components/ui/Icon'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'

import useShareProductsUrl from './ShareProduct/useShareProductUrl'
import { ImageGallerySelector, ImageZoom } from '.'
import X from '../Icons/SocialMedia/X'
import ShareIcon from '../Icons/SocialMedia/ShareIcon'
import styles from './image-gallery.module.scss'
import './ShareProduct/share-product.scss'

export interface ImageElementData {
  url: string
  alternateName: string
}

interface ImageGalleryProps {
  images: ImageElementData[]
  locationHref: string
}

function ImageGallery({ images, locationHref }: ImageGalleryProps) {
  const [FIRST_IMAGE] = images
  const [selectedImageIdx, setSelectedImageIdx] = useState(0)
  const currentImage = images[selectedImageIdx] ?? FIRST_IMAGE
  const hasSelector = images.length > 1
  const { isMobile } = useWindowDimensions()
  const url = useShareProductsUrl(locationHref)

  return (
    <section
      data-fs-image-gallery={!hasSelector ? 'without-selector' : ''}
      className={styles.fsImageGallery}
    >
      {url !== null ? (
        <ShareProduct
          CloseIcon={X}
          ShareIcon={ShareIcon}
          shareProductText="Comparte este producto"
          shareWebSocials="Comparte en social media"
          shareLinks={url}
          productURL={locationHref}
        />
      ) : null}
      <div className="image-gallery__arrows-container">
        <button
          onClick={() => {
            selectedImageIdx > 0 && setSelectedImageIdx(selectedImageIdx - 1)
          }}
          className={
            selectedImageIdx > 0
              ? 'image-gallery__arrow-left'
              : 'image-gallery__arrow-left-disabled'
          }
        >
          <Icon name="LeftArrow" width={24} height={24} />
        </button>
        <button
          onClick={() => {
            selectedImageIdx < images.length - 1 &&
              setSelectedImageIdx(selectedImageIdx + 1)
          }}
          className={
            selectedImageIdx < images.length - 1
              ? 'image-gallery__arrow-right'
              : 'image-gallery__arrow-right-disabled'
          }
        >
          <Icon name="RightArrow" width={24} height={24} />
        </button>
      </div>
      <ImageZoom>
        <Image
          src={currentImage?.url}
          alt={currentImage?.alternateName}
          sizes="(max-width: 804px) 25vw, 30vw"
          width={isMobile ? 220 : 347}
          height={isMobile ? 220 : 347}
          loading="eager"
          fetchPriority="high"
        />
      </ImageZoom>
      {hasSelector && (
        <ImageGallerySelector
          images={images}
          currentImageIdx={selectedImageIdx}
          onSelect={setSelectedImageIdx}
        />
      )}
    </section>
  )
}

export default ImageGallery
