import type { CurrencyCode, ViewItemEvent } from '@faststore/sdk'
import { sendAnalyticsEvent } from '@faststore/sdk'
import type { ProductDetailsFragment_ProductFragment } from '@generated/graphql'
import { graphql } from 'gatsby'
import { useEffect, useState } from 'react'
import useOnScreen from 'src/components/hooks/useOnScreen'
import useWindowDimensions from 'src/components/hooks/useWindowDimensions'
import OutOfStock from 'src/components/product/OutOfStock'
import Breadcrumb from 'src/components/ui/Breadcrumb'
import BuyButton from 'src/components/ui/BuyButton'
import { ImageGallery } from 'src/components/ui/ImageGallery'
import Price from 'src/components/ui/Price'
import ProductTitle from 'src/components/ui/ProductTitle'
import QuantitySelector from 'src/components/ui/QuantitySelector'
import Selectors from 'src/components/ui/SkuSelector/Selectors'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import { cartStore, useCart } from 'src/sdk/cart'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'
import { useDiscountPercent } from 'src/sdk/product/useDiscountPercent'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import { useProduct } from 'src/sdk/product/useProduct'
import { useSession } from 'src/sdk/session'

import Section from '../Section'
import { MessageIndisponible, MessageStock } from './MessageStock'
import { ShowAccordion } from './ShowAccordion'

interface Props {
  product: ProductDetailsFragment_ProductFragment
  locationHref: string
}

function ProductDetails({ product: staleProduct }: Props) {
  const INITIAL_QUANTITY = 1
  const HAS_WINDOW = typeof window !== 'undefined'
  const { currency } = useSession()
  const [quantityItems, setQuantityItems] = useState<number>(INITIAL_QUANTITY)
  const { items } = useCart()
  const { removeItem, updateItemQuantity } = cartStore
  const { isMobile } = useWindowDimensions()
  const [indicesExpanded, setIndicesExpanded] = useState<Set<number>>(
    new Set([])
  )

  const [isIndexExpanded, setisIndexExpanded] = useState<boolean>(false)
  const { data, isValidating } = useProduct(staleProduct.id, {
    product: staleProduct,
  })

  const [eventAction, setEventAction] = useState<string | null>(null)

  const { inView } = useOnScreen()

  if (!data) {
    throw new Error('NotFound')
  }

  const {
    product: {
      id,
      sku,
      gtin,
      description,
      clusterHighlights,
      name: variantName,
      brand,
      isVariantOf,
      isVariantOf: { name, skuVariants },
      image: productImages,
      offers: {
        offers: [{ availability, price, listPrice, seller }],
        lowPrice,
      },
      unitMultiplier,
      measurementUnit,
      breadcrumbList: breadcrumbs,
      additionalProperty,
    },
  } = data
  const buyDisabled = availability !== 'https://schema.org/InStock'

  const coleccion = () => {
    let coleccionText = ''
    const stringArray = clusterHighlights

    stringArray.map((item: { name: string }) => {
      coleccionText = `${coleccionText} ${item.name}`
    })

    return coleccionText
  }

  const itemValues = {
    id,
    quantity: 1,
    price,
    listPrice,
    seller,
    itemOffered: {
      image: productImages?.map((img) => ({
        url: img?.url,
        alternateName: img?.alternateName,
      })),
      additionalProperty,
      sku,
      name: variantName,
      gtin,
      brand: {
        name: brand?.name,
      },
      isVariantOf: {
        productGroupID: isVariantOf?.productGroupID,
        name: isVariantOf?.name,
      },
    },
  }

  const buyProps = useBuyButton(
    {
      id,
      price,
      listPrice,
      seller,
      quantity: quantityItems,
      unitMultiplier,
      measurementUnit,
      itemOffered: {
        sku,
        name: variantName,
        gtin,
        image: productImages,
        brand,
        isVariantOf,
        additionalProperty,
      },
    },
    itemValues,
    eventAction
  )

  const priceKg = (1 / unitMultiplier) * lowPrice
  const lowPriceKg = (listPrice * unitMultiplier).toFixed(2)
  const floatLowPriceKg = parseFloat(lowPriceKg)

  let spotPriceKg = price
  let realPrice = price

  if (unitMultiplier) {
    spotPriceKg = unitMultiplier <= 1 ? (1 / unitMultiplier) * price : price

    realPrice = unitMultiplier > 1 ? price * unitMultiplier : price
  }

  const discountPercentage = useDiscountPercent(floatLowPriceKg, realPrice)
  const hasDiscount = discountPercentage >= 0
  const cartProduct = items?.find((item) => item?.itemOffered?.sku === sku)

  useEffect(() => {
    if (HAS_WINDOW) {
      const searchParams = new URLSearchParams(window.location.search)
      const eventActionParam = searchParams.get('isbraindw')

      if (eventActionParam) {
        setEventAction(eventActionParam)
      }
    }
  }, [])

  useEffect(() => {
    sendAnalyticsEvent<ViewItemEvent<AnalyticsItem>>({
      name: 'view_item',
      params: {
        currency: currency.code as CurrencyCode,
        value: price,
        items: [
          {
            item_id: isVariantOf.productGroupID,
            item_name: isVariantOf.name,
            item_brand: brand.name,
            item_variant: sku,
            price,
            discount: listPrice - price,
            currency: currency.code as CurrencyCode,
            item_variant_name: variantName,
            product_reference_id: gtin,
          },
        ],
      },
    })
  }, [
    isVariantOf.productGroupID,
    isVariantOf.name,
    brand.name,
    sku,
    price,
    listPrice,
    currency.code,
    variantName,
    gtin,
  ])

  useEffect(() => {
    if (!cartProduct) {
      setQuantityItems(INITIAL_QUANTITY)
    }
  }, [cartProduct])

  const onChange = (index: number) => {
    if (indicesExpanded.has(index)) {
      indicesExpanded.delete(index)
      setIndicesExpanded(new Set(indicesExpanded))
      setisIndexExpanded(false)
    } else {
      setIndicesExpanded(new Set(indicesExpanded.add(index)))
      setisIndexExpanded(true)
    }
  }

  return (
    <Section className="product-details layout__content layout__section">
      <div className="product-details__breadcrumb">
        <Breadcrumb breadcrumbList={breadcrumbs.itemListElement} />
      </div>
      <section className="product-details__body">
        <p className={`global-pdp__flags flag ${coleccion()}`}>coleccion</p>
        <ImageGallery
          images={productImages}
          locationHref={HAS_WINDOW ? window.location.href : ''}
        />

        <header className="product-details__title">
          <h2>{brand?.name}</h2>
          <ProductTitle title={<h1>{name}</h1>} />
          <section className="product-details__settings">
            <section className="product-details__values">
              <div className="product-details__prices">
                {floatLowPriceKg <= realPrice ? null : (
                  <div
                    className={
                      hasDiscount
                        ? 'product-details__discount-wrapper'
                        : 'product-details__discount-wrapper-blank'
                    }
                  >
                    <Price
                      value={floatLowPriceKg}
                      formatter={useFormattedPrice}
                      testId="list-price"
                      data-value={floatLowPriceKg}
                      variant="listing"
                      classes="text__legend"
                      SRText="Original price:"
                    />
                  </div>
                )}
                <div
                  className={
                    hasDiscount
                      ? 'product-details__price-wrapper product-details__discount-wrapper'
                      : 'product-details__price-wrapper product-details__discount-wrapper-blank'
                  }
                >
                  <Price
                    value={realPrice}
                    formatter={useFormattedPrice}
                    testId="price"
                    data-value={realPrice}
                    variant="spot"
                    classes="text__lead"
                    SRText="Sale Price:"
                  />
                  {discountPercentage === 0 ? (
                    <></>
                  ) : (
                    <p>{`${discountPercentage}% OFF`}</p>
                  )}
                </div>
              </div>

              {measurementUnit === 'kg' && (
                <div className="price_unit_container ">
                  <Price
                    value={spotPriceKg}
                    formatter={useFormattedPrice}
                    classes="text_price_unit_pdp"
                    SRText="Sale Price:"
                  />
                  <p className="text_price_unit_pdp">x {measurementUnit}</p>
                </div>
              )}
            </section>

            {skuVariants && (
              <Selectors
                slugsMap={skuVariants.slugsMap}
                availableVariations={skuVariants.availableVariations}
                activeVariations={skuVariants.activeVariations}
                data-fs-product-details-selectors
              />
            )}
            {!discountPercentage && buyDisabled && <MessageIndisponible />}
            {/* NOTE: animation violation due to the button transitioning commit https://git.io/JyXV5. */}
            {isValidating ? (
              <AddToCartLoadingSkeleton />
            ) : (
              <>
                {cartProduct?.id && availability && (
                  <div className="product-details__cantidad">
                    <span>Cantidad:</span>
                  </div>
                )}
                <div className="product-details__buy-options">
                  {!cartProduct?.id && availability && (
                    <div className="buy-options__button-wrapper">
                      <BuyButton
                        className="buy-options__buy-button"
                        disabled={buyDisabled}
                        {...buyProps}
                      >
                        AGREGAR
                      </BuyButton>
                    </div>
                  )}
                  {cartProduct?.id && availability && (
                    <div className="buy-options__quantity-wrapper">
                      <QuantitySelector
                        cartQuantity={cartProduct?.quantity}
                        quantity={quantityItems}
                        setQuantity={setQuantityItems}
                        productId={cartProduct?.id}
                        removeItem={removeItem}
                        updateItems={updateItemQuantity}
                        className="buy-options__quantity-selector"
                      />
                    </div>
                  )}
                </div>
                {isMobile && <MessageStock />}
                {/* {!isMobile || inView ? (
                  <DeliveryBox product={staleProduct} />
                ) : null} */}
              </>
            )}
            {!availability && (
              <OutOfStock
                onSubmit={(email) => {
                  console.info(email)
                }}
              />
            )}
          </section>
        </header>
      </section>
      {!isMobile && <MessageStock />}
      <section className="product-details__content">
        {isMobile ? (
          ShowAccordion(indicesExpanded, onChange, description, isIndexExpanded)
        ) : (
          <article className="product-details__description">
            <h2 className="text__title-subsection">Descripción</h2>
            <p className="text__body">{description}</p>
          </article>
        )}
      </section>
    </Section>
  )
}

function AddToCartLoadingSkeleton() {
  // Generated via https://skeletonreact.com/.
  return (
    <svg
      role="img"
      width="100%"
      height="48"
      aria-labelledby="loading-aria"
      viewBox="0 0 112 48"
      preserveAspectRatio="none"
    >
      <title id="loading-aria">Loading...</title>
      <rect
        x="0"
        y="0"
        width="100%"
        height="100%"
        clipPath="url(#clip-path)"
        style={{ fill: 'url("#fill")' }}
      />
      <defs>
        <clipPath id="clip-path">
          <rect x="0" y="0" rx="2" ry="2" width="112" height="48" />
        </clipPath>
        <linearGradient id="fill">
          <stop offset="0.599964" stopColor="#f3f3f3" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-2; -2; 1"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="1.59996" stopColor="#ecebeb" stopOpacity="1">
            <animate
              attributeName="offset"
              values="-1; -1; 2"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="2.59996" stopColor="#f3f3f3" stopOpacity="1">
            <animate
              attributeName="offset"
              values="0; 0; 3"
              keyTimes="0; 0.25; 1"
              dur="2s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const fragment = graphql`
  fragment ProductDetailsFragment_product on StoreProduct {
    id: productID
    sku
    name
    gtin
    description
    clusterHighlights {
      id
      name
    }
    unitMultiplier
    measurementUnit
    isVariantOf {
      productGroupID
      name
      skuVariants {
        activeVariations
        slugsMap(dominantVariantName: "Talle")
        availableVariations(dominantVariantName: "Talle")
      }
    }
    image {
      url
      alternateName
    }
    brand {
      name
    }
    offers {
      lowPrice
      offers {
        availability
        price
        listPrice
        seller {
          identifier
        }
      }
    }
    breadcrumbList {
      itemListElement {
        item
        name
        position
      }
    }
    additionalProperty {
      propertyID
      name
      value
      valueReference
    }
  }
`

export default ProductDetails
