import { useRef } from 'react'
import { Button } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'

import type { ImageElementData } from './ImageGallery'
import styles from './image-gallery-selector.module.scss'

interface Props {
  images: ImageElementData[]
  onSelect: React.Dispatch<React.SetStateAction<number>>
  currentImageIdx: number
}

function ImageGallerySelector({ images, onSelect, currentImageIdx }: Props) {
  const elementsRef = useRef<HTMLDivElement>(null)

  return (
    <section
      data-fs-image-gallery-selector
      className={styles.fsImageGallerySelector}
      aria-roledescription="carousel"
      aria-label="Product images"
    >
      <div data-fs-image-gallery-selector-elements ref={elementsRef}>
        {images.map((image, idx) => {
          return (
            <Button
              key={idx}
              aria-label={`${image.alternateName} - Image ${idx + 1} of ${
                images.length
              }`}
              onClick={() => onSelect(idx)}
              data-fs-image-gallery-selector-thumbnail={
                idx === currentImageIdx ? 'selected' : 'true'
              }
            >
              <Image
                src={image.url}
                alt={image.alternateName}
                loading={idx === 0 ? 'eager' : 'lazy'}
                sizes="(max-width: 72px) 25vw, 30vw"
                width={48}
                height={48}
              />
            </Button>
          )
        })}
      </div>
      {/* {elementHasScroll && !lastImageInView && (
        <IconButton
          data-fs-image-gallery-selector-control-button
          aria-label="forward slide image selector"
          icon={<Icon name="ArrowLeft" width={24} height={24} />}
          onClick={() => moveScroll(elementsRef.current, +SCROLL_MARGIN_VALUE)}
        /> */}
    </section>
  )
}

export default ImageGallerySelector
