export default function IconMail() {
  return (
    <svg
      width="18 "
      height="14"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.03033 0.96967C1.73744 0.676777 1.26256 0.676777 0.96967 0.96967C0.676777 1.26256 0.676777 1.73744 0.96967 2.03033L2.03033 0.96967ZM11.4142 8.58579L10.8839 8.05546L11.4142 8.58579ZM19.0303 2.03033C19.3232 1.73744 19.3232 1.26256 19.0303 0.96967C18.7374 0.676777 18.2626 0.676777 17.9697 0.96967L19.0303 2.03033ZM2 1.75H18V0.25H2V1.75ZM18.25 2V14H19.75V2H18.25ZM18 14.25H2V15.75H18V14.25ZM1.75 14V2H0.25V14H1.75ZM2 14.25C1.86193 14.25 1.75 14.1381 1.75 14H0.25C0.25 14.9665 1.0335 15.75 2 15.75V14.25ZM18.25 14C18.25 14.1381 18.1381 14.25 18 14.25V15.75C18.9665 15.75 19.75 14.9665 19.75 14H18.25ZM18 1.75C18.1381 1.75 18.25 1.86193 18.25 2H19.75C19.75 1.0335 18.9665 0.25 18 0.25V1.75ZM2 0.25C1.0335 0.25 0.25 1.0335 0.25 2H1.75C1.75 1.86193 1.86193 1.75 2 1.75V0.25ZM0.96967 2.03033L8.05546 9.11612L9.11612 8.05546L2.03033 0.96967L0.96967 2.03033ZM11.9445 9.11612L19.0303 2.03033L17.9697 0.96967L10.8839 8.05546L11.9445 9.11612ZM8.05546 9.11612C9.1294 10.1901 10.8706 10.1901 11.9445 9.11612L10.8839 8.05546C10.3957 8.54361 9.60427 8.54361 9.11612 8.05546L8.05546 9.11612Z"
        fill="#292929"
      />
    </svg>
  )
}
